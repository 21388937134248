export const bandcampPath = 'https://ripship.bandcamp.com';
export const spotifyPath =
  'https://open.spotify.com/artist/1yx7HUETsdOE9oP6ISSVSX?si=NS-IbuAwSHaOMiOR70V1TA';
export const instagramPath = 'https://www.instagram.com/ripship_/';
export const facebookPath = 'https://www.facebook.com/ripshipband';
export const tiktokPath = 'https://www.tiktok.com/@ripship_';
export const youtubePath = 'https://www.youtube.com/@ripship_';

export const CRAWLING_CHORUS_PREORDER =
  'https://ripship.bandcamp.com/album/crawling-chorus';

//GIG LINKS
export const AKL_GIG =
  'https://www.undertheradar.co.nz/gig/94252/Ripship-Album-Release-w-Mullbrain--Tba.utr';
export const WLG_GIG =
  'https://www.moshtix.co.nz/v2/event/ripship-album-release-w-wiri-donna-sleeping-village-quartet/175884';
export const DUN_GIG =
  'https://www.undertheradar.co.nz/gig/94253/Ripship-Album-Release-w-Dale-Kerrigan--Bunchys-Big-Score.utr';
export const OSEES_GIG = 'https://www.moshtix.co.nz/v2/event/osees/173038';

//DEBUGGER LINKS
export const DEBUG_BANDCAMP = 'https://ripship.bandcamp.com/track/debugger';
export const DEBUG_SPOTIFY =
  'https://open.spotify.com/album/4UwXu65QYExznS7jppROfs?si=EM3nTHJzQ2OZBMFfBmc8Ag';
export const DEBUG_YOUTUBE = 'https://youtu.be/9RLCsPXFCFU?si=ISOWkbmGY6tZDfal';

//NEW MEXICO LINKS
export const NEWMEX_BANDCAMP = 'https://ripship.bandcamp.com/track/new-mexico';
export const NEWMEX_SPOTIFY =
  'https://open.spotify.com/album/64WbvlQhZHUlPLyGUfhBF2?si=xnR9E_ezT4uhrDGSAtX7hQ';
export const NEWMEX_YOUTUBE =
  'https://youtu.be/FxhAAjjoq1o?si=5gw3kp5KndzVjhUG';

//CRAWLING CHORUS
export const CRAWLING_CHORUS_BANDCAMP =
  'https://ripship.bandcamp.com/album/crawling-chorus';
export const CRAWLING_CHORUS_SPOTIFY =
  'https://open.spotify.com/album/4aJCjuhuqlQGvBQ7rPU521?si=KaTS7iWpR4OPX8INwCxxtw';
export const CRAWLING_CHORUS_YOUTUBE =
  'https://youtube.com/playlist?list=OLAK5uy_m_Gsbtwo28znyhUx_8AZzl_xgOXklcmvQ&si=QdpeL2FoPQHbtg-h';
